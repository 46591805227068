import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, catchError, from, mergeMap, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AccessInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.addAuthHeader(req))
            .pipe(
                mergeMap((modifiedReq) => next.handle(modifiedReq)),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    switch (error.status) {
                        case 400:
                            errorMessage = 'Bad Request'
                            break;
                        case 401:
                            sessionStorage.clear();
                            this.authService.login();
                            break;
                        default:
                            errorMessage = `Error! ${error.status} . Please try again after some time.`;
                            break;
                    }
                    if (error instanceof HttpErrorResponse) {
                        return throwError(() => error);
                    } else {
                        console.log('Interceptor caught a client-side error:', error);
                        return throwError(() => error);
                    }
                })
            );

    }

    async addAuthHeader(req: HttpRequest<any>): Promise<HttpRequest<any>> {
        const accessToken = await this.authService.getAccessToken();
        if (accessToken) {
            req = req.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
        }
        return req;
    }
}